import { FC, Fragment, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';

import { EmptyList } from 'src/components/empty-list/empty-list';
import { getTheme } from 'src/utils/get-theme';

import { config } from '../constants/config';
import { FAQ_DATA } from '../constants/faq-data';
import { searchElements } from '../constants/search-elements';

import { FaqSearchInput } from './faq-search-input';
import { QuestionAnswer } from './question-answer';
import { FaqVideoLinks } from './faq-video-links';
import { FaqRulesDisclaimers } from './faq-rules-disclaimers';
import { FaqRulesDocuments } from './faq-rules-documents';

export const SearchSection: FC = () => {
    const {
        t,
        i18n: { language },
    } = useTranslation();

    const [searchParams] = useSearchParams();
    const searchValue = (searchParams.get(config.search_query_key) || '').toLocaleLowerCase();
    const { isHosTheme } = getTheme();

    const qaList = useMemo(
        () =>
            FAQ_DATA.reduce((accum, qaData) => {
                const question = t(qaData.question);
                const answer = t(qaData.answer);

                if (
                    !(
                        question.toLocaleLowerCase().includes(searchValue) ||
                        answer.toLocaleLowerCase().includes(searchValue)
                    )
                ) {
                    return accum;
                }

                accum.push(
                    <QuestionAnswer
                        question={<Trans i18nKey={question} components={searchElements} />}
                        answer={
                            <Fragment key={qaData.question}>
                                <Trans i18nKey={answer} components={searchElements} />

                                {qaData.question ===
                                    'faq_question_whats_included_at_vita_whats-in-my-building' && (
                                    <FaqVideoLinks />
                                )}

                                {qaData.question === 'faq_question_home-rules_disclaimers' && (
                                    <FaqRulesDisclaimers />
                                )}

                                {qaData.question === 'faq_question_home-rules_documents' && (
                                    <FaqRulesDocuments />
                                )}
                            </Fragment>
                        }
                    />
                );

                return accum;
            }, [] as JSX.Element[]),
        [searchValue, language]
    );

    return (
        <section css={{ flex: 1, padding: '16px' }}>
            <div
                css={{
                    maxWidth: '420px',
                    margin: 'auto',
                    textAlign: 'center',
                    marginBottom: '48px',
                }}
            >
                <FaqSearchInput />

                {searchValue && (
                    <Typography
                        variant="h5"
                        color={isHosTheme ? 'text.primary' : 'text.secondary'}
                        marginTop="56px"
                        css={{
                            ...(isHosTheme && {
                                textTransform: 'uppercase',
                            }),
                        }}
                    >
                        {t('help_search_results_results_number', {
                            number_of_results: qaList.length,
                            keyword: searchValue,
                        })}
                    </Typography>
                )}
            </div>

            <div css={{ maxWidth: '700px', margin: 'auto auto 64px auto' }}>
                {qaList.length ? (
                    qaList
                ) : (
                    <EmptyList
                        mainText={<Trans i18nKey="help_search_results_empty_state_title" />}
                        secondaryText={<Trans i18nKey="help_search_results_empty_state_subtitle" />}
                    />
                )}
            </div>
        </section>
    );
};
