import { FC, ReactNode } from 'react';
import { Box, Stack, Typography, useTheme } from '@mui/material';

import { ReactComponent as UnionIcon } from './union.svg';

export const EmptyList: FC<{
    mainText?: ReactNode;
    secondaryText?: ReactNode;
    actionRow?: ReactNode;
    icon?: ReactNode;
    className?: string;
}> = ({ mainText, secondaryText, actionRow, icon, className }) => {
    const { palette } = useTheme();

    return (
        <Stack
            flex={1}
            textAlign="center"
            alignItems="center"
            justifyContent="center"
            height="100%"
            className={className}
        >
            <Stack flex={{ xs: 1, md: 0 }} justifyContent="center" alignItems="center">
                <Stack
                    position="relative"
                    justifyContent="center"
                    alignItems="center"
                    width="162px"
                    height="162px"
                >
                    <Box position="absolute">
                        {icon || <UnionIcon color={palette.primary.main} />}
                    </Box>

                    <Typography variant="h2" zIndex={1}>
                        {mainText}
                    </Typography>
                </Stack>

                <Typography variant="body2" color="text.secondary" marginTop="39px">
                    {secondaryText}
                </Typography>
            </Stack>

            {actionRow && (
                <Box width="100%" marginTop="80px">
                    {actionRow}
                </Box>
            )}
        </Stack>
    );
};
