import { useTheme } from '@emotion/react';
import { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { Button } from 'src/components/button';
import { getTheme } from 'src/utils/get-theme';

import onboardingData from '../../mocks/onboarding-slides.json';
import hosImage from '../../../../assets/jpg/onboarding-image-hos.jpg';

import { ProposalModal } from './parts/proposal-modal';

export const Onboarding: FC<{ passedOnboarding: boolean; onSkip: VoidFunction }> = ({
    passedOnboarding,
    onSkip,
}) => {
    const { t } = useTranslation();
    const { breakpoints } = useTheme();
    const { isHosTheme } = getTheme();

    const updatedImage = isHosTheme ? { md: hosImage, lg: hosImage } : onboardingData.imageVS;
    const title = isHosTheme ? onboardingData.titleHoS : onboardingData.titleVS;

    return (
        <ProposalModal
            open={!passedOnboarding}
            title={<Trans i18nKey={title} />}
            content={
                isHosTheme ? t(onboardingData.descriptionHoS) : t(onboardingData.descriptionVS)
            }
            image={updatedImage}
            footer={
                <Button
                    design="primary"
                    fullWidth
                    onClick={onSkip}
                    css={{
                        [breakpoints.up('md')]: {
                            maxWidth: '340px',
                            alignSelf: 'center',
                        },
                    }}
                >
                    {t(onboardingData.cta)}
                </Button>
            }
        />
    );
};
