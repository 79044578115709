import { FC, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import { useTheme } from '@emotion/react';
import { Divider, Stack, Typography, IconButton, Box } from '@mui/material';

import { LanguagesCode } from 'src/i18n/constants';
import { BlockWithIcon } from 'src/components/block-with-icon';
import { routes } from 'src/constants/routes';
import { useFeatureFlags, useGetCurrentUser, UserAvatar } from 'src/features/user';
import { BookingsOverview, useIsCheckedIn } from 'src/features/bookings';
import { ReactComponent as IconArrow } from 'src/assets/svg/control-arrow.svg';
import { ReactComponent as QuestionIcon } from 'src/assets/svg/question-in-circle.svg';
import { ReactComponent as FeedbackIcon } from 'src/assets/svg/chat-with-star.svg';
import { ReactComponent as VideoIcon } from 'src/assets/svg/video.svg';
import { PromotionCards } from 'src/features/promotions';
import { MoveInCard, MoveOutCard } from 'src/features/move-in';
import { RebookingBanner, RebookStatus, useGetRebookingPhase } from 'src/features/rebooking';
import { GA4 } from 'src/lib/ga4';

import { ReactComponent as ChairIcon } from './parts/chair.svg';

export const HomePage: FC = () => {
    const { breakpoints, palette } = useTheme();
    const { t } = useTranslation();

    const featureFlags = useFeatureFlags();
    const isCheckedIn = useIsCheckedIn();

    const { data: phaseInfo } = useGetRebookingPhase();
    const { data: user } = useGetCurrentUser();

    const phaseStatus = phaseInfo?.rebook_status;
    const rebookingEnded = phaseStatus === RebookStatus.Ended;

    const isNominationUser =
        !featureFlags.move_in &&
        !featureFlags.move_out &&
        !featureFlags.booking &&
        !featureFlags.make_payment;

    const handleClick = () =>
        GA4.ctaEvent({
            name: 'View Profile',
            label: t('profile_update', { lng: LanguagesCode.English }),
            location: 'Home Page',
        });

    const getTitleKey = () =>
        isNominationUser && user?.first_name
            ? 'booking_overview_title_nominations'
            : 'booking_overview_title';

    return (
        <Fragment>
            <Box padding="36px 16px 16px 16px" display={{ md: 'none' }}>
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    marginBottom="16px"
                    onClick={handleClick}
                    css={{ textDecoration: 'none' }}
                >
                    <UserAvatar onClick={handleClick} />

                    <IconButton onClick={handleClick} component={Link} to={routes.profile.index}>
                        <IconArrow
                            css={{
                                transform: 'rotate(270deg)',
                                stroke: palette.text.primary,
                            }}
                        />
                    </IconButton>
                </Stack>
                <Divider />
            </Box>

            <Stack
                direction={{ xs: 'column', lg: 'row' }}
                gap={{ xs: '48px', lg: '0' }}
                flex={1}
                css={{
                    [breakpoints.down('md')]: {
                        background: palette.background.paper,
                    },
                }}
            >
                {featureFlags.rebook && !rebookingEnded && (
                    <RebookingBanner
                        css={{
                            marginTop: '8px',
                            paddingBottom: '0',
                            borderBottom: 'none',

                            [breakpoints.up('md')]: {
                                display: 'none',
                            },
                        }}
                    />
                )}

                <Stack
                    gap="20px"
                    css={{
                        flex: 1,
                        padding: '24px 16px',
                        background: palette.background.paper,

                        [breakpoints.up('lg')]: {
                            maxWidth: '560px',
                            padding: '120px 60px 60px 60px',
                        },
                    }}
                >
                    <Typography variant="h2" marginBottom={{ xs: '12px', md: '32px' }}>
                        <Trans
                            i18nKey={getTitleKey()}
                            values={{ firstName: user?.first_name?.toLocaleUpperCase() }}
                        />
                    </Typography>

                    {featureFlags.move_in && <MoveInCard />}

                    {featureFlags.move_out && <MoveOutCard />}

                    {featureFlags.make_payment && <BookingsOverview />}

                    <Stack gap="16px">
                        <BlockWithIcon
                            icon={<QuestionIcon />}
                            title={t('help_page_link_title').toLocaleUpperCase()}
                            body={t('help_page_link_subtitle')}
                            link={routes.supportHub.index}
                        />

                        <Divider />

                        {featureFlags.booking && (
                            <BlockWithIcon
                                icon={<ChairIcon />}
                                title={t('page_link_title').toLocaleUpperCase()}
                                body={t('my_bookings_page_link_subtitle')}
                                link={routes.myBooking.index}
                            />
                        )}

                        <Divider />

                        {featureFlags.feedback && isCheckedIn && (
                            <Fragment>
                                <BlockWithIcon
                                    icon={<FeedbackIcon />}
                                    title={t('my_account_feedback_reviews_title')}
                                    body={t('my_account_feedback_reviews_subtitle')}
                                    link={routes.feedback.index}
                                />
                                <Divider />
                            </Fragment>
                        )}

                        {featureFlags.building_videos && (
                            <BlockWithIcon
                                icon={<VideoIcon />}
                                title={t('home_video-guides-link_title')}
                                body={t('home_video-guides-link_description')}
                                link={routes.guides.index}
                            />
                        )}
                    </Stack>
                </Stack>

                <Box
                    padding={{ xs: '0 16px', md: '0 16px 32px 16px', lg: '120px 60px 60px 60px' }}
                    flex={1}
                    css={{
                        [breakpoints.up('lg')]: {
                            background: palette.background.default,
                        },
                    }}
                >
                    <Typography variant="h2" marginBottom={{ xs: '32px', md: '52px' }}>
                        {t('exclusive_offers_title').toLocaleUpperCase()}
                    </Typography>

                    <Box
                        display="grid"
                        gridTemplateColumns={{ xs: '1fr', xl: '1fr 1fr' }}
                        gap="32px"
                    >
                        {featureFlags.rebook && (
                            <RebookingBanner
                                css={{
                                    [breakpoints.down('md')]: {
                                        display: rebookingEnded ? undefined : 'none',
                                        margin: '0 -16px',
                                    },
                                }}
                            />
                        )}

                        <PromotionCards />
                    </Box>
                </Box>
            </Stack>
        </Fragment>
    );
};
