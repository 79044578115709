import { FC } from 'react';
import { useTheme } from '@emotion/react';
import { Paper, Typography, IconButton, Box, useMediaQuery } from '@mui/material';

import { ReactComponent as DownloadFileIcon } from 'src/assets/svg/download-file.svg';
import { getTheme } from 'src/utils/get-theme';
import { theme } from 'src/styles/theme';

interface Props {
    name: string;
    href: string;
    download?: true;
    onClick?: VoidFunction;
}

export const BookingDocsItem: FC<Props> = ({ name, href, download, onClick }) => {
    const { palette, transitions } = useTheme();
    const { isHosTheme } = getTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('lg'));

    return (
        <Paper
            component="a"
            target="_blank"
            href={href}
            onClick={onClick}
            download={download}
            elevation={0}
            square
            css={{
                display: 'flex',
                alignItems: 'center',
                gap: '16px',
                color: 'inherit',
                textDecoration: 'inherit',

                ':hover': {
                    '[aria-label="file title"]': {
                        ':after': {
                            width: '100%',
                        },
                    },
                },
                ...(isHosTheme && {
                    background: palette.background.default,
                }),
            }}
        >
            <Box flex={1} paddingX="20px">
                <Typography
                    variant="subtitle1"
                    width="fit-content"
                    aria-label="file title"
                    css={{
                        paddingBottom: '2px',
                        position: 'relative',

                        ':after': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            height: '1px',
                            width: 0,
                            bottom: 0,
                            background: palette.text.primary,
                            transition: transitions.create('width'),
                        },
                    }}
                >
                    {name}
                </Typography>
            </Box>

            <IconButton
                css={{
                    width: '70px',
                    height: '70px',
                    background: palette.primary.main,
                    color: palette.common.white,
                    borderRadius: 0,
                    ...(isHosTheme &&
                        isSmallScreen && {
                            background: palette.common.black,
                        }),
                }}
            >
                <DownloadFileIcon />
            </IconButton>
        </Paper>
    );
};
