import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Stack, Typography } from '@mui/material';
import { useTheme } from '@emotion/react';

import { appConfig } from 'src/app/app-config';
import { liveChat } from 'src/services/live-chat';
import { ButtonWithIcon } from 'src/components/button-with-icon';

import { ReactComponent as ChatIcon } from './parts/chat-us.svg';
import { ReactComponent as EmailIcon } from './parts/email-us.svg';
import { ReactComponent as CallbackIcon } from './parts/callback-us.svg';

interface Props {
    variant: 'light' | 'dark';
    showChat?: boolean;
    className?: string;
    onClickEmail?: VoidFunction;
    onClickCallBack?: VoidFunction;
    onClickLiveChat?: VoidFunction;
}

export const ContactsRow: FC<Props> = ({
    variant,
    showChat,
    className,
    onClickEmail,
    onClickCallBack,
    onClickLiveChat,
}) => {
    const { t } = useTranslation();
    const { palette } = useTheme();

    const handleClickLiveChat = () => {
        liveChat.maximize();
        onClickLiveChat?.();
    };

    return (
        <Stack data-testid="ContactRow.container" gap="12px" marginTop="16px" className={className}>
            {showChat && (
                <ButtonWithIcon
                    design={variant}
                    rightIcon={<ChatIcon color={palette.primary.main} />}
                    onClick={handleClickLiveChat}
                    bordered
                >
                    <Typography variant="h6">{t('contact_live_chat')}</Typography>
                </ButtonWithIcon>
            )}

            <ButtonWithIcon
                design={variant}
                href={appConfig.contacts.customerManagement}
                rightIcon={<EmailIcon color={palette.primary.main} />}
                onClick={onClickEmail}
                bordered
            >
                <Typography variant="h6">{t('contact_email')}</Typography>
            </ButtonWithIcon>

            <ButtonWithIcon
                design={variant}
                href={appConfig.contacts.callbackForm}
                target="_blank"
                onClick={onClickCallBack}
                rightIcon={<CallbackIcon color={palette.primary.main} />}
                bordered
            >
                <Typography variant="h6">{t('contact_callback')}</Typography>
            </ButtonWithIcon>
        </Stack>
    );
};
