import { FC, ReactNode } from 'react';
import { Stack, Typography } from '@mui/material';

import { getTheme } from 'src/utils/get-theme';

export const UserDataRow: FC<{ label: string; content: ReactNode }> = ({ label, content }) => {
    const { isHosTheme } = getTheme();

    return (
        <Stack
            direction={{ xs: 'row', lg: 'column' }}
            alignItems={{ xs: 'center', lg: 'flex-start' }}
            justifyContent="space-between"
            gap="1em"
            paddingY="16px"
            css={({ palette }) => ({
                '&:not(:last-of-type)': {
                    borderBottom: `1px solid ${palette.divider}`,
                },
            })}
        >
            <Typography variant="body2" color={isHosTheme ? 'text.primary' : 'text.secondary'}>
                {label}
            </Typography>
            <Typography
                variant="body2"
                component={content instanceof Object ? 'div' : 'p'}
                textAlign="right"
            >
                {content ?? '—'}
            </Typography>
        </Stack>
    );
};
