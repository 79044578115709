import { FC, Fragment, MouseEventHandler, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@emotion/react';
import { Button, useMediaQuery } from '@mui/material';

import { getPartialTranslation } from 'src/utils/get-partial-translation';
import { trimText } from 'src/utils/trim-text';
import { GA4 } from 'src/lib/ga4';
import { getTheme } from 'src/utils/get-theme';

import { BuildingCard } from '../building-card';
import { BuildingOffer } from '../../api';

const TEXT_LIMIT = 140;

export const BuildingOffersItem: FC<{
    offer: BuildingOffer;
    onShowMore: (content: string) => void;
}> = ({ offer, onShowMore }) => {
    const { palette, breakpoints } = useTheme();
    const { isHosTheme } = getTheme();

    const { t } = useTranslation();

    const [seeMore, setSeeMore] = useState(false);

    const isDesktop = useMediaQuery(breakpoints.up('lg'));

    const description = getPartialTranslation(offer.description);

    const handleSeeMore: MouseEventHandler = (e) => {
        e.preventDefault();

        return isDesktop ? onShowMore(description) : setSeeMore(!seeMore);
    };

    const handleClick = () => GA4.outboundLink('Offer Link', offer.external_url ?? '');

    return (
        <BuildingCard
            key={offer.id}
            image={offer.image_url}
            title={getPartialTranslation(offer.title)}
            onClick={handleClick}
            body={
                <Fragment>
                    {seeMore ? description : `${trimText(description, TEXT_LIMIT)} `}

                    {!seeMore && description.length > TEXT_LIMIT && (
                        <Button
                            onClick={handleSeeMore}
                            css={{
                                padding: 0,
                                verticalAlign: 'unset',
                                font: 'inherit',
                                color: isHosTheme ? palette.primary.main : palette.text.secondary,

                                ':hover': { background: 'inherit' },
                            }}
                        >
                            {t('offers_description_see-more')}
                        </Button>
                    )}
                </Fragment>
            }
            label={getPartialTranslation(offer.tag)}
            link={
                offer.external_url
                    ? {
                          text: t('offers_go_to_offer_button'),
                          url: offer.external_url,
                          showButton: true,
                      }
                    : undefined
            }
            css={{
                [breakpoints.down('lg')]: {
                    ':not(:last-of-type)': {
                        borderBottom: `1px solid ${palette.divider}`,
                    },
                },

                [breakpoints.up('lg')]: {
                    '.building-card__description': {
                        padding: '21px 0 24px 0',
                    },
                },
            }}
        />
    );
};
