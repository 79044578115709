import { forwardRef, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTheme } from '@emotion/react';
import {
    Box,
    Checkbox,
    FormControlLabel,
    IconButton,
    Stack,
    TextField,
    Typography,
} from '@mui/material';

import { PrivateHat } from 'src/layout/private-hat';
import { Button } from 'src/components/button';
import { ReactComponent as CrossIcon } from 'src/assets/svg/cross.svg';
import { StateView } from 'src/components/state-view';
import { appConfig } from 'src/app/app-config';
import { GA4 } from 'src/lib/ga4';
import { LanguagesCode } from 'src/i18n/constants';
import { ConciergeResponseCode } from 'src/api/concierge';
import {
    addAttachments,
    deleteAttachment,
    AttachmentError,
    setAttachmentError,
    AttachmentsGallery,
} from 'src/components/attachment-gallery';
import { getTheme } from 'src/utils/get-theme';
import { hosPalette } from 'src/styles/constants';

import { ReactComponent as IssueTypeIcon } from '../../assets/svg/issue-type.svg';
import { ReactComponent as IssueCategoryIcon } from '../../assets/svg/issue-category.svg';
import { ReactComponent as IssueProblemIcon } from '../../assets/svg/issue-problem.svg';
import { ReactComponent as IssueLocationIcon } from '../../assets/svg/issue-location.svg';
import { ReactComponent as IssueDetailsIcon } from '../../assets/svg/issue-details.svg';
import { usePostTicket } from '../../hooks/use-post-ticket';
import schema from '../../validation/create-ticket';
import {
    CreateTicketFormFields,
    DEFAULT_FULFILLED_PERCENT,
    FORM_REQUIRED_STEPS,
    FORM_STEPS,
    LOCATION_OPTIONS,
} from '../../constants/create-ticket-form';
import { useGetGridOptionTranslator } from '../../hooks/use-grid-option-translator';
import { MAINTENANCE_ATTACHMENT_ERROR_KEYS } from '../../constants/attachments-error-translation-keys';

import { AccordionField } from './parts/accordion-field';
import { GridOptionsSelect } from './parts/grid-options-select';

import type { CreateTicketFormData } from '../../types/create-ticket-form';
import type { MaintenanceTicketFormDataTree } from '../../api';

interface Props {
    dataTree: MaintenanceTicketFormDataTree;
    onClose: VoidFunction;
}

const STEP_LABEL_MAP = {
    [CreateTicketFormFields.IssueType]: 'maintenance_report_issue-type_label',
    [CreateTicketFormFields.Issue]: 'maintenance_report_issue_label',
    [CreateTicketFormFields.Location]: 'maintenance_report_problem_label',
    [CreateTicketFormFields.Problem]: 'maintenance_report_issue-location_label',
    [CreateTicketFormFields.DescriptionText]: 'maintenance_report_issue-details_label',
    [CreateTicketFormFields.DescriptionAttachments]:
        'maintenance_report_issue-details_attached-files',
};

export const CreateMaintenanceTicket = forwardRef<HTMLDivElement, Props>(
    ({ dataTree, onClose }, ref) => {
        const { control, formState, watch, handleSubmit, setValue, setError, clearErrors } =
            useForm<CreateTicketFormData>({
                reValidateMode: 'onSubmit',
                resolver: yupResolver(schema),
            });

        const formData = watch();

        const { mutate, isLoading, isSuccess, isError, isIdle, reset } = usePostTicket({
            onError: () =>
                setAttachmentError(CreateTicketFormFields.DescriptionAttachments, setError),
        });

        const isFormError = isError && formState.isValid;

        const [activeStep, setActiveStep] = useState<CreateTicketFormFields>(FORM_STEPS[0]);
        const [isAgreementAccepted, setAgreementAccepted] = useState(false);

        const { palette, breakpoints } = useTheme();
        const { isHosTheme } = getTheme();
        const { t } = useTranslation();

        const translateGridOption = useGetGridOptionTranslator();

        const progress = useMemo(() => {
            const stepsAmount = FORM_REQUIRED_STEPS.length + 1;
            const percent = stepsAmount / 100;
            const oneStepPercent = 1 / percent;

            const changedFieldsAmount = FORM_REQUIRED_STEPS.reduce((accum, step) => {
                const isDone = Boolean(formState.dirtyFields[step] && !formState.errors[step]);
                return Number(isDone) + accum;
            }, 0);

            const fulfilledPercent = changedFieldsAmount * oneStepPercent;
            const result = fulfilledPercent + Number(isAgreementAccepted) * oneStepPercent;

            return result || DEFAULT_FULFILLED_PERCENT;
        }, [formData, isAgreementAccepted]);

        const onExpand = (nextStep: CreateTicketFormFields) => () => {
            setActiveStep(nextStep);

            GA4.ctaEvent({
                name: 'Manual Maintenance Ticket Step Change',
                label: t(STEP_LABEL_MAP[nextStep], {
                    lng: LanguagesCode.English,
                }),
                location: 'Create Maintenance Ticket Form',
            });
        };

        const checkAgreement = (event: unknown, checked: boolean) => setAgreementAccepted(checked);

        const handleFormSubmit = handleSubmit((formValues) => {
            mutate(formValues);

            GA4.submitEvent({
                name: 'Report an Issue Form Submit',
                label: t('maintenance_report-issue_button_submit', { lng: LanguagesCode.English }),
                location: 'Report an Issue Modal',
            });
        });

        const handleAccordionFieldChange =
            <FieldName extends CreateTicketFormFields>(name: FieldName) =>
            async (payload?: CreateTicketFormData[FieldName]) => {
                if (name === CreateTicketFormFields.IssueType) {
                    setValue(CreateTicketFormFields.Issue, undefined);
                    setValue(CreateTicketFormFields.Problem, undefined);
                } else if (name === CreateTicketFormFields.Issue) {
                    setValue(CreateTicketFormFields.Problem, undefined);
                }

                setValue(name, payload as never, {
                    shouldDirty: true,
                    shouldTouch: true,
                    shouldValidate: true,
                });

                const nextIndex = FORM_STEPS.findIndex((step) => step === name) + 1;
                const nextStep = FORM_STEPS[nextIndex];

                if (nextStep) {
                    setActiveStep(nextStep);
                }
            };

        return (
            <Stack
                ref={ref}
                tabIndex={-1}
                css={{
                    overflow: 'auto',
                    position: 'absolute',
                    top: 0,
                    bottom: 0,
                    left: 0,
                    right: 0,
                    background: palette.background.default,
                }}
            >
                {!isFormError && (
                    <PrivateHat
                        showBackIcon={false}
                        title={isSuccess ? '' : t('maintenance_report_page-title')}
                        progress={isSuccess ? undefined : progress}
                        actionLabel={
                            <IconButton onClick={onClose}>
                                <CrossIcon />
                            </IconButton>
                        }
                    />
                )}

                <Stack flex={1} padding="0 16px 24px 16px">
                    <Stack
                        flex={1}
                        justifyContent={isIdle ? 'flex-start' : 'center'}
                        alignItems="center"
                        position="relative"
                        css={{
                            background: isHosTheme
                                ? hosPalette.background.paper
                                : palette.background.default,
                        }}
                    >
                        {!isSuccess && !isFormError && (
                            <Box
                                component="form"
                                name="maintenance ticket"
                                width="100%"
                                marginTop={{ xs: '44px', md: '64px' }}
                                maxWidth={{ md: '640px' }}
                                onSubmit={handleFormSubmit}
                            >
                                <Box>
                                    <AccordionField
                                        expanded={activeStep === CreateTicketFormFields.IssueType}
                                        icon={<IssueCategoryIcon />}
                                        title={t('maintenance_report_issue-type_label')}
                                        error={
                                            formState.errors[CreateTicketFormFields.IssueType] &&
                                            t(
                                                formState.errors[CreateTicketFormFields.IssueType]
                                                    .message!
                                            )
                                        }
                                        label={translateGridOption(
                                            formData[CreateTicketFormFields.IssueType]
                                        )}
                                        onExpand={onExpand(CreateTicketFormFields.IssueType)}
                                    >
                                        <Controller
                                            name={CreateTicketFormFields.IssueType}
                                            control={control}
                                            render={({ field }) => (
                                                <GridOptionsSelect
                                                    value={field.value}
                                                    options={dataTree}
                                                    onChange={handleAccordionFieldChange(
                                                        field.name
                                                    )}
                                                />
                                            )}
                                        />
                                    </AccordionField>

                                    <AccordionField
                                        label={translateGridOption(
                                            formData[CreateTicketFormFields.Issue]
                                        )}
                                        expanded={activeStep === CreateTicketFormFields.Issue}
                                        icon={<IssueTypeIcon />}
                                        title={t('maintenance_report_issue_label')}
                                        disabled={!formData[CreateTicketFormFields.IssueType]}
                                        error={
                                            formState.errors[CreateTicketFormFields.Issue] &&
                                            t(
                                                formState.errors[CreateTicketFormFields.Issue]
                                                    .message!
                                            )
                                        }
                                        onExpand={onExpand(CreateTicketFormFields.Issue)}
                                    >
                                        <Controller
                                            name={CreateTicketFormFields.Issue}
                                            control={control}
                                            render={({ field }) => (
                                                <GridOptionsSelect
                                                    value={field.value}
                                                    options={
                                                        formData[CreateTicketFormFields.IssueType]
                                                            ?.issues
                                                    }
                                                    onChange={handleAccordionFieldChange(
                                                        field.name
                                                    )}
                                                />
                                            )}
                                        />
                                    </AccordionField>

                                    <AccordionField
                                        label={translateGridOption(
                                            formData[CreateTicketFormFields.Problem]
                                        )}
                                        expanded={activeStep === CreateTicketFormFields.Problem}
                                        icon={<IssueProblemIcon />}
                                        title={t('maintenance_report_problem_label')}
                                        disabled={!formData[CreateTicketFormFields.Issue]}
                                        error={
                                            formState.errors[CreateTicketFormFields.Problem] &&
                                            t(
                                                formState.errors[CreateTicketFormFields.Problem]
                                                    .message!
                                            )
                                        }
                                        onExpand={onExpand(CreateTicketFormFields.Problem)}
                                    >
                                        <Controller
                                            name={CreateTicketFormFields.Problem}
                                            control={control}
                                            render={({ field }) => (
                                                <GridOptionsSelect
                                                    value={field.value}
                                                    options={
                                                        formData[CreateTicketFormFields.Issue]
                                                            ?.problems
                                                    }
                                                    onChange={handleAccordionFieldChange(
                                                        field.name
                                                    )}
                                                />
                                            )}
                                        />
                                    </AccordionField>

                                    <AccordionField
                                        expanded={activeStep === CreateTicketFormFields.Location}
                                        icon={<IssueLocationIcon />}
                                        title={t('maintenance_report_issue-location_label')}
                                        error={
                                            formState.errors[CreateTicketFormFields.Location] &&
                                            t(
                                                formState.errors[CreateTicketFormFields.Location]
                                                    .message!
                                            )
                                        }
                                        label={translateGridOption(
                                            formData[CreateTicketFormFields.Location]
                                        )}
                                        disabled={
                                            !formState.touchedFields[CreateTicketFormFields.Problem]
                                        }
                                        onExpand={onExpand(CreateTicketFormFields.Location)}
                                    >
                                        <Controller
                                            name={CreateTicketFormFields.Location}
                                            control={control}
                                            render={({ field }) => (
                                                <GridOptionsSelect
                                                    value={field.value}
                                                    options={LOCATION_OPTIONS}
                                                    onChange={handleAccordionFieldChange(
                                                        field.name
                                                    )}
                                                />
                                            )}
                                        />
                                    </AccordionField>

                                    <AccordionField
                                        title={t('maintenance_report_issue-details_label')}
                                        error={
                                            formState.errors[
                                                CreateTicketFormFields.DescriptionText
                                            ] &&
                                            t(
                                                formState.errors[
                                                    CreateTicketFormFields.DescriptionText
                                                ].message!
                                            )
                                        }
                                        label={formData[CreateTicketFormFields.DescriptionText]}
                                        expanded={
                                            activeStep === CreateTicketFormFields.DescriptionText
                                        }
                                        icon={<IssueDetailsIcon />}
                                        disabled={
                                            !formState.touchedFields[
                                                CreateTicketFormFields.Location
                                            ]
                                        }
                                        onExpand={onExpand(CreateTicketFormFields.DescriptionText)}
                                    >
                                        <Controller
                                            name={CreateTicketFormFields.DescriptionText}
                                            control={control}
                                            defaultValue=""
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    variant="standard"
                                                    label={t(
                                                        'maintenance_report_issue-details_textfield_label_details'
                                                    )}
                                                    InputLabelProps={{ shrink: true }}
                                                    placeholder={t(
                                                        'maintenance_report_issue-details_textfield_placeholder_details'
                                                    )}
                                                    fullWidth
                                                    css={{ marginBottom: '16px' }}
                                                />
                                            )}
                                        />

                                        <Controller
                                            name={CreateTicketFormFields.DescriptionAttachments}
                                            control={control}
                                            defaultValue={[]}
                                            render={({ field, fieldState }) => (
                                                <Stack gap="12px" marginTop="8px">
                                                    <Typography
                                                        variant="body2"
                                                        color="text.secondary"
                                                        gutterBottom
                                                    >
                                                        {t(
                                                            'maintenance_report_issue-details_attached-files',
                                                            {
                                                                count: field.value?.length,
                                                            }
                                                        )}
                                                    </Typography>

                                                    <AttachmentsGallery
                                                        attachments={field.value}
                                                        limit={appConfig.attachments.maxCount}
                                                        onChange={addAttachments(
                                                            field,
                                                            clearErrors,
                                                            setError
                                                        )}
                                                        onDelete={deleteAttachment(
                                                            field,
                                                            clearErrors,
                                                            setError
                                                        )}
                                                    />

                                                    {fieldState.error && (
                                                        <AttachmentError
                                                            translationsMap={
                                                                MAINTENANCE_ATTACHMENT_ERROR_KEYS
                                                            }
                                                            errorCode={
                                                                fieldState.error
                                                                    .message as ConciergeResponseCode
                                                            }
                                                        />
                                                    )}
                                                </Stack>
                                            )}
                                        />
                                    </AccordionField>
                                </Box>

                                <Box textAlign="center" marginTop={{ xs: '28px', md: '40px' }}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={isAgreementAccepted}
                                                onChange={checkAgreement}
                                                css={{ padding: 0 }}
                                            />
                                        }
                                        label={t('maintenance_report_checkbox_permissions')}
                                        css={{
                                            alignItems: 'flex-start',
                                            textAlign: 'left',
                                            gap: '12px',
                                        }}
                                    />
                                </Box>

                                <Box textAlign="center" marginTop={{ xs: '52px', md: '60px' }}>
                                    <Button
                                        design="primary"
                                        type="submit"
                                        fullWidth
                                        disableElevation
                                        disabled={!(formState.isValid && isAgreementAccepted)}
                                        loading={isLoading}
                                        css={{
                                            [breakpoints.up('md')]: {
                                                maxWidth: '280px',
                                            },
                                        }}
                                    >
                                        {t('maintenance_report-issue_button_submit')}
                                    </Button>
                                </Box>
                            </Box>
                        )}

                        {isFormError && (
                            <StateView
                                state="fail"
                                actions={{
                                    main: {
                                        text: t('maintenance_error_button_retry-to-submit-issue'),
                                        callback: reset,
                                    },
                                    secondary: {
                                        text: t(
                                            'maintenance_error_button_go-to-all-reported-issues'
                                        ),
                                        callback: onClose,
                                    },
                                }}
                                title={<Trans i18nKey="maintenance_error_something-went-wrong" />}
                            />
                        )}

                        {isSuccess && (
                            <StateView
                                state="success"
                                title={t('maintenance_issue-submitted_title')}
                                actions={{
                                    main: {
                                        text: t(
                                            'maintenance_issue-submitted_button_go-to-all-reported-issues'
                                        ),
                                        callback: onClose,
                                    },
                                }}
                            >
                                <Typography variant="body1">
                                    {t('maintenance_issue-submitted_description')}
                                </Typography>
                            </StateView>
                        )}
                    </Stack>
                </Stack>
            </Stack>
        );
    }
);
