import { FC } from 'react';
import { useTheme } from '@emotion/react';
import { Typography, Button, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { ReactComponent as WarningIcon } from 'src/assets/svg/warning.svg';
import { ReactComponent as RetryIcon } from 'src/assets/svg/retry.svg';
import { ConciergeResponseCode } from 'src/api/concierge';
import { getTheme } from 'src/utils/get-theme';
import { hosPalette } from 'src/styles/constants';

export const ProfileAvatarError: FC<{
    errorCode: ConciergeResponseCode;
    onRetry?: VoidFunction;
}> = ({ errorCode, onRetry }) => {
    const { palette, breakpoints } = useTheme();
    const { isHosTheme } = getTheme();

    const { t } = useTranslation();

    let errorMessage = '';
    let isGeneralError = false;

    switch (errorCode) {
        case ConciergeResponseCode.InvalidUploadFileType:
            errorMessage = t('profile_file_unsupported_upload_error');
            break;
        case ConciergeResponseCode.InvalidUploadFileSize:
            errorMessage = t('profile_file_too_big_upload_error');
            break;
        default:
            errorMessage = t('upload_file_error');
            isGeneralError = true;
            break;
    }

    return (
        <Stack
            justifyContent="center"
            alignItems="center"
            gap={!isGeneralError ? '12px' : '6px'}
            css={{
                background: isHosTheme ? hosPalette.error.paleRed : palette.error.dark,
                color: isHosTheme ? hosPalette.error.main : palette.common.white,
                border: `1px solid ${palette.error.main}`,
                width: '100%',
                padding: '16px',
                textAlign: 'center',

                [breakpoints.up('md')]: {
                    minHeight: '50%',
                    padding: '20px 50px',
                    position: 'absolute',
                    zIndex: 2,
                    top: 0,
                },
            }}
        >
            {!isGeneralError && <WarningIcon width={32} height={32} />}

            <Typography color="inherit" variant="subtitle2" gutterBottom>
                {errorMessage}
            </Typography>

            {isGeneralError && (
                <Button onClick={onRetry} variant="text" color="inherit" endIcon={<RetryIcon />}>
                    {t('retry_cta')}
                </Button>
            )}
        </Stack>
    );
};
