import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import { useTheme } from '@emotion/react';
import { Typography, Box, Alert, Stack } from '@mui/material';

import { useDateLocale } from 'src/hooks/use-date-locale';
import { getPartialTranslation } from 'src/utils/get-partial-translation';
import { ReactComponent as SuccessIcon } from 'src/assets/svg/success-in-circle.svg';
import { getTheme } from 'src/utils/get-theme';

import { BuildingIncident } from '../../api/types';
import { BuildingUpdatesStatus } from '../../api/constants';
import { ReactComponent as PinIcon } from '../../assets/svg/pin.svg';

const CHEAP_LABEL = {
    [BuildingUpdatesStatus.Active]: 'building_incidents_status_active',
    [BuildingUpdatesStatus.Resolved]: 'building_incidents_status_resolved',
};

export const BuildingIncidentsItem: FC<{ incident: BuildingIncident }> = ({ incident }) => {
    const { t } = useTranslation();
    const locale = useDateLocale();

    const { palette, shadows } = useTheme();
    const { isHosTheme } = getTheme();

    const isResolved = incident.status === BuildingUpdatesStatus.Resolved;

    return (
        <Box
            padding="24px"
            css={{
                position: 'relative',
                zIndex: 'auto',
                background: palette.background.default,

                '::after': {
                    content: '""',
                    display: 'block',
                    position: 'absolute',
                    zIndex: -1,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    top: 0,
                    boxShadow: shadows[1],
                },
            }}
        >
            <Alert
                severity={isResolved ? 'success' : 'info'}
                variant="filled"
                css={{
                    padding: '4px 12px',
                    width: 'fit-content',
                    marginBottom: '28px',
                    backgroundColor: isHosTheme && !isResolved ? palette.error.main : undefined,
                }}
            >
                {t(CHEAP_LABEL[incident.status])}
            </Alert>

            <Typography
                variant="h4"
                marginBottom="16px"
                flex={1}
                color={isResolved ? 'text.disabled' : 'text.default'}
                textTransform="uppercase"
                css={{
                    textDecoration: isResolved ? 'line-through' : undefined,
                }}
            >
                {getPartialTranslation(incident.title)}
            </Typography>

            <Stack
                direction="row"
                gap="16px"
                padding="16px"
                marginBottom="16px"
                alignItems="center"
                css={{ background: palette.background.paper }}
            >
                {isResolved ? (
                    <SuccessIcon color={palette.success.dark} width="24px" height="24px" />
                ) : (
                    <PinIcon color={palette.primary.main} width="24px" height="24px" />
                )}

                <Typography variant="subtitle2">
                    {incident.latest_comment
                        ? incident.latest_comment.content
                        : getPartialTranslation(incident.description)}
                </Typography>
            </Stack>

            <Typography variant="body2" color="text.secondary">
                {format(new Date(incident.updated_date), 'MMM dd, yyyy, HH:mm', { locale })}
            </Typography>
        </Box>
    );
};
