import { forwardRef, useEffect, useMemo } from 'react';
import { TFunction, Trans, useTranslation } from 'react-i18next';
import { isToday, isYesterday, format, Locale } from 'date-fns';
import { useTheme } from '@emotion/react';
import { Stack, Typography, IconButton } from '@mui/material';

import { useDateLocale } from 'src/hooks/use-date-locale';
import { PrivateHat } from 'src/layout/private-hat';
import { EmptyList } from 'src/components/empty-list/empty-list';
import { ErrorState } from 'src/components/error-state';
import { Spinner } from 'src/components/spinner';
import { ReactComponent as CrossIcon } from 'src/assets/svg/cross-in-square.svg';
import { getTheme } from 'src/utils/get-theme';

import { useGetUserNotifications } from '../../hooks/use-get-user-notifications';
import { Notifications } from '../../api/types';

import { NotificationItem } from './parts/notification-item';

interface Props {
    isModal: boolean;
    onClose?: VoidFunction;
}

const formatNotificationDate = (date: Date, locale: Locale, t: TFunction) => {
    if (isToday(date)) {
        return t('notifications_today');
    }

    if (isYesterday(date)) {
        return t('notifications_yesterday');
    }

    return format(date, 'MMM d, yyyy', { locale });
};

export const NotificationsList = forwardRef<HTMLDivElement, Props>(({ isModal, onClose }, ref) => {
    const { palette } = useTheme();
    const dateLocale = useDateLocale();
    const { t } = useTranslation();
    const { isHosTheme } = getTheme();

    const { data, isError, isLoading, isSuccess, refetch } = useGetUserNotifications();

    const notificationsByDate = useMemo(() => {
        if (!isSuccess) return null;

        return data.reduce((map, notification) => {
            const date = new Date(notification.created_at);
            const dateLabel = formatNotificationDate(date, dateLocale, t);

            const container = map.get(dateLabel);

            if (Array.isArray(container)) {
                container.push(notification);
            } else {
                map.set(dateLabel, [notification]);
            }

            return map;
        }, new Map<string, Notifications>());
    }, [data, dateLocale]);

    useEffect(
        () => () => {
            refetch();
        },
        []
    );

    return (
        <Stack ref={ref} tabIndex={-1} flex={1} height="100%">
            <PrivateHat
                title={t('notifications_model_title')}
                showBackIcon={!isModal}
                actionLabel={
                    isModal && (
                        <IconButton
                            onClick={onClose}
                            css={{ position: 'absolute', right: 0, top: 0, padding: 0 }}
                        >
                            <CrossIcon color={palette.primary.main} />
                        </IconButton>
                    )
                }
                css={{
                    '.PrivateHat__header-wrap': {
                        padding: '40px 16px 20px 16px',
                    },
                }}
            />

            {isError && <ErrorState title={<Trans i18nKey="notifications_error_state" />} />}

            {isLoading && (
                <Stack width="100%" height="100%" alignItems="center" justifyContent="center">
                    <Spinner size="big" width="33%" height="33%" color={palette.primary.main} />
                </Stack>
            )}

            {isSuccess && (
                <Stack flex={1} alignItems="center">
                    {notificationsByDate!.size ? (
                        (() => {
                            const result: JSX.Element[] = [];

                            notificationsByDate!.forEach((notifications, label) =>
                                result.push(
                                    // eslint-disable-next-line react/no-array-index-key
                                    <div key={label} css={{ width: '100%' }}>
                                        <Typography
                                            variant="body2"
                                            margin="32px 16px 16px 16px"
                                            color={isHosTheme ? 'text.primary' : 'text.secondary'}
                                        >
                                            {label}
                                        </Typography>

                                        <div>
                                            {notifications.map((notification) => (
                                                <NotificationItem
                                                    key={notification.id}
                                                    notification={notification}
                                                    onClick={onClose}
                                                />
                                            ))}
                                        </div>
                                    </div>
                                )
                            );

                            return result;
                        })()
                    ) : (
                        <EmptyList
                            mainText={<Trans i18nKey="notifications_empty_state_title" />}
                            secondaryText={<Trans i18nKey="notifications_empty_state_subtitle" />}
                        />
                    )}
                </Stack>
            )}
        </Stack>
    );
});
