import { FC, ReactEventHandler, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@emotion/react';
import { Breakpoint, CircularProgress, Stack } from '@mui/material';

import { getTheme } from 'src/utils/get-theme';

import { ErrorState } from './error-state';

export type SrcSet = {
    [breakpoint in Breakpoint]?: string;
};

export const Image: FC<{
    src?: string;
    srcSet?: SrcSet;
    sizes?: string;
    alt?: string;
    className?: string;
}> = ({ src, srcSet, sizes, alt, className }) => {
    const { t } = useTranslation();
    const { palette, breakpoints } = useTheme();
    const { isHosTheme } = getTheme();

    const [isLoading, setLoading] = useState(true);
    const [isError, setError] = useState(false);

    const handleLoad: ReactEventHandler<HTMLImageElement> = () => {
        setLoading(false);
        setError(false);
    };

    const handleError: ReactEventHandler<HTMLImageElement> = () => {
        setLoading(false);
        setError(true);
    };

    useEffect(() => {
        setLoading(true);
        setError(false);
    }, [src, srcSet]);

    return (
        <Stack
            position="relative"
            width="100%"
            height="100%"
            justifyContent="center"
            alignItems="center"
            direction="row"
            className={className}
            css={{
                overflow: 'hidden',
                background: isHosTheme ? palette.background.default : palette.background.paper,
            }}
        >
            {isError && <ErrorState direction="column" title={t('image_loading_error')} />}

            {isLoading && <CircularProgress css={{ color: palette.text.secondary }} />}

            {srcSet ? (
                <picture
                    css={{
                        width: '100%',
                        height: '100%',
                        position: 'absolute',
                    }}
                    hidden={isError}
                    onLoad={handleLoad}
                    onError={handleError}
                >
                    {Object.entries(srcSet).map(([breakpoint, image_src]) => (
                        <source
                            key={`banner-preview-${breakpoint}`}
                            media={`(min-width:${breakpoints.values[breakpoint as Breakpoint]}px)`}
                            srcSet={image_src}
                        />
                    ))}
                    <img src={srcSet.xs ?? src} alt={alt} width="100%" height="100%" />
                </picture>
            ) : (
                <img
                    src={src}
                    sizes={sizes}
                    alt={alt}
                    hidden={isError}
                    width="100%"
                    height="100%"
                    onLoad={handleLoad}
                    onError={handleError}
                    css={{
                        position: 'absolute',
                    }}
                />
            )}
        </Stack>
    );
};
