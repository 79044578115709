import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, useTheme } from '@mui/material';

import { ContactsRow } from 'src/components/contacts-row/contacts-row';
import { GA4 } from 'src/lib/ga4';
import { LanguagesCode } from 'src/i18n/constants';
import { getTheme } from 'src/utils/get-theme';
import { hosPalette } from 'src/styles/constants';

export const ContactUs: FC = () => {
    const { t } = useTranslation();
    const { palette, breakpoints } = useTheme();
    const { isHosTheme } = getTheme();

    const handleClickEmail = () =>
        GA4.ctaEvent({
            name: 'Email Us Click',
            label: t('contact_email', { lng: LanguagesCode.English }),
            location: 'Support Hub Contact',
        });

    const handleClickCallBack = () =>
        GA4.ctaEvent({
            name: 'Call Back Click',
            label: t('contact_callback', { lng: LanguagesCode.English }),
            location: 'Support Hub Contact',
        });

    const handleClickLiveChat = () =>
        GA4.ctaEvent({
            name: 'Live Chat Click',
            label: t('contact_live_chat', { lng: LanguagesCode.English }),
            location: 'Support Hub Contact',
        });

    return (
        <footer
            css={{
                background: isHosTheme ? hosPalette.background.brandBlue : palette.grey[900],
                color: palette.common.white,
            }}
        >
            <div
                css={{
                    maxWidth: breakpoints.values.md,
                    margin: '0 auto',
                    textAlign: 'center',
                    padding: '36px 16px',
                    boxSizing: 'content-box',
                }}
            >
                <Typography variant="h4" marginBottom="32px">
                    {t('help_contact_title')}
                </Typography>

                <ContactsRow
                    variant="dark"
                    showChat
                    css={{
                        [breakpoints.up('lg')]: {
                            flexDirection: 'row',
                        },
                    }}
                    onClickEmail={handleClickEmail}
                    onClickLiveChat={handleClickLiveChat}
                    onClickCallBack={handleClickCallBack}
                />
            </div>
        </footer>
    );
};
