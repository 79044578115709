import { FC } from 'react';
import { Button, Stack, Typography, useTheme } from '@mui/material';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Image } from 'src/components/image';
import { IconLabel } from 'src/components/icon-label';
import { ReactComponent as CalendarSmallIcon } from 'src/assets/svg/calendar-empty.svg';
import { LanguagesCode } from 'src/i18n/constants';
import { useFormattedDate } from 'src/hooks/use-formatted-date';
import { Event } from 'src/features/events';
import { GA4 } from 'src/lib/ga4';
import { getPartialTranslation } from 'src/utils/get-partial-translation';
import { getTheme } from 'src/utils/get-theme';
import { hosPalette } from 'src/styles/constants';

interface ButtonProps {
    event: Event;
    onEventChange?: (id: string) => void;
    link?: never;
    className?: string;
}

interface LinkProps {
    event: Event;
    onEventChange?: never;
    link?: string;
    className?: string;
}

type Props = LinkProps | ButtonProps;

export const EventCard: FC<Props> = ({ event, onEventChange, link, className }) => {
    useTranslation();

    const getFormattedDate = useFormattedDate();

    const { palette } = useTheme();
    const { isHosTheme } = getTheme();

    const title = getPartialTranslation(event.title);

    const onClick = () => {
        onEventChange?.(event.id);

        GA4.ctaEvent({
            name: 'Feedback Event Selected',
            location: 'Feedback Form',
            label: event.title[LanguagesCode.English],
        });
    };

    const cardProps = onEventChange
        ? {
              component: Button,
              onClick,
          }
        : {
              component: Link,
              to: link,
          };

    const hoverStyles = {
        color: isHosTheme ? hosPalette.secondary.light : palette.text.primary,
        background: isHosTheme ? hosPalette.background.brandBlue : palette.background.paper,
    };

    const background = isHosTheme ? palette.background.default : palette.background.paper;

    return (
        <Stack
            {...cardProps}
            color={palette.text.primary}
            flexDirection="row"
            alignItems="stretch"
            textAlign="left"
            padding={0}
            className={className}
            css={{
                textDecoration: 'none',
                borderRadius: 0,
            }}
        >
            <Image
                alt={title}
                src={event.image_url}
                css={{
                    width: '33%',
                    height: 'auto',
                    position: 'relative',
                    flexShrink: 0,
                    img: {
                        position: 'absolute',
                    },
                }}
            />

            <Stack
                padding={{ xs: '16px', md: '24px' }}
                gap="12px"
                flexGrow={1}
                css={{
                    background,
                    '&:hover': hoverStyles,
                }}
            >
                <Typography variant="subtitle3">{title}</Typography>

                <IconLabel icon={<CalendarSmallIcon />}>
                    {getFormattedDate(event.start_date, 'dd.MM.yyyy, HH:mm')}
                </IconLabel>
            </Stack>
        </Stack>
    );
};
