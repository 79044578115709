import { forwardRef, MouseEventHandler } from 'react';
import {
    IconButton,
    Dialog as MuiDialog,
    DialogContent,
    DialogActions,
    DialogProps,
    Stack,
} from '@mui/material';
import { PropsOf, useTheme } from '@emotion/react';

import { Button } from 'src/components/button';
import { ReactComponent as CrossInSquareIcon } from 'src/assets/svg/cross-in-square.svg';
import { getTheme } from 'src/utils/get-theme';

import { Spinner } from './spinner';

interface Props extends DialogProps {
    loading?: boolean;
    actions?: {
        decline?: PropsOf<typeof Button>;
        accept?: PropsOf<typeof Button>;
    };
}

/**
 * The extended version of the MUI DIalog. Passes in an Event Handler for close events, and pre-styles the dialog
 */
export const Dialog = forwardRef<HTMLDivElement, Props>(
    ({ open, loading, children, actions, onClose, ...restProps }, ref) => {
        const { palette, breakpoints, zIndex } = useTheme();
        const { isHosTheme } = getTheme();

        return (
            <MuiDialog
                ref={ref}
                open={open}
                maxWidth={false}
                disableAutoFocus
                onClose={loading ? undefined : onClose}
                css={{
                    overflowY: 'auto',
                    minHeight: '100%',

                    '.MuiDialog-paper': {
                        width: '100%',
                        margin: '16px',
                        maxWidth: '613px',
                        borderRadius: 0,
                        background: palette.common.white,
                        gap: '44px',

                        [breakpoints.up('md')]: {
                            gap: isHosTheme ? '54px' : '94px',
                        },
                    },
                    ...(isHosTheme && {
                        background: palette.common.black,
                    }),
                }}
                {...restProps}
            >
                {loading && (
                    <div
                        css={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            position: 'absolute',
                            background: palette.background.default,
                            zIndex: zIndex.modal,
                            left: 0,
                            right: 0,
                            top: 0,
                            bottom: 0,
                        }}
                    >
                        <Spinner color={palette.primary.main} />
                    </div>
                )}

                <div
                    css={{
                        position: 'sticky',
                        textAlign: 'right',
                        top: 0,
                        background: palette.background.default,
                    }}
                >
                    <IconButton onClick={onClose as MouseEventHandler} css={{ padding: 0 }}>
                        <CrossInSquareIcon
                            width="50px"
                            height="50px"
                            color={palette.primary.main}
                        />
                    </IconButton>
                </div>

                <Stack
                    flex={1}
                    padding={{ xs: '0 16px 48px 16px', md: '0 80px 80px 80px' }}
                    gap="44px"
                >
                    <DialogContent
                        css={{
                            display: 'flex',
                            justifyContent: 'center',
                            overflow: 'hidden',
                            flex: 1,
                            padding: 0,
                        }}
                    >
                        {children}
                    </DialogContent>

                    {actions && (
                        <DialogActions
                            disableSpacing
                            css={{
                                alignItems: 'center',
                                justifyContent: 'center',
                                flexDirection: 'column-reverse',
                                gap: '12px',
                                padding: 0,

                                [breakpoints.up('md')]: {
                                    flexDirection: 'row',
                                    gap: '20px',
                                },
                            }}
                        >
                            {actions.decline && (
                                <Button
                                    bordered
                                    css={{
                                        width: '100%',
                                        [breakpoints.up('md')]: {
                                            width: '50%',
                                        },
                                    }}
                                    {...actions.decline}
                                />
                            )}

                            {actions.accept && (
                                <Button
                                    design="primary"
                                    css={{
                                        width: '100%',
                                        [breakpoints.up('md')]: {
                                            width: '50%',
                                        },
                                    }}
                                    {...actions.accept}
                                />
                            )}
                        </DialogActions>
                    )}
                </Stack>
            </MuiDialog>
        );
    }
);
