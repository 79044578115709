import { memo } from 'react';
import { useLocation } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import { Dialog, DialogContent, IconButton, Stack, Typography } from '@mui/material';
import { useTheme } from '@emotion/react';

import { Button } from 'src/components/button';
import { ReactComponent as CrossIcon } from 'src/assets/svg/cross-in-square.svg';
import { getTheme } from 'src/utils/get-theme';

import { useAuth0 } from '../hooks/use-auth0';
import { setPreLogoutPath } from '../services/pre-logout-path';

export const LogoutDialog = memo<{
    isOpen: boolean;
    onClose: VoidFunction;
    onLogout?: VoidFunction;
}>(({ isOpen, onClose, onLogout }) => {
    const { logout } = useAuth0();
    const { pathname, search } = useLocation();
    const { t } = useTranslation();
    const { palette } = useTheme();
    const { isHosTheme } = getTheme();

    const handleLogout = () => {
        setPreLogoutPath(pathname + search);
        logout({ returnTo: window.location.origin });

        onLogout?.();
    };

    return (
        <Dialog
            open={isOpen}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            PaperProps={{ elevation: 0 }}
            css={{
                padding: '16px',

                '.MuiBackdrop-root': {
                    backgroundColor: 'rgba(0, 0, 0, 0.8)',
                    ...(isHosTheme && {
                        background: palette.common.black,
                    }),
                },

                '.MuiDialog-paper': {
                    borderRadius: 0,
                    maxWidth: '613px',
                    width: '100%',
                    margin: 0,
                },
            }}
        >
            <Stack
                padding={{ xs: '124px 16px 60px 16px', md: '144px 80px 80px 80px' }}
                position="relative"
            >
                <IconButton
                    onClick={onClose}
                    css={{ position: 'absolute', right: 0, top: 0, padding: 0 }}
                >
                    <CrossIcon color={palette.primary.main} />
                </IconButton>

                <Stack justifyContent="center" flex={1}>
                    <DialogContent>
                        <Typography variant="h3" marginBottom="44px" textAlign="center">
                            <Trans i18nKey="logout_confirmation" />
                        </Typography>
                    </DialogContent>

                    <Stack direction={{ md: 'row-reverse' }} gap={{ xs: '12px', md: '20px' }}>
                        <Button
                            design="primary"
                            fullWidth
                            disableElevation
                            onClick={handleLogout}
                            css={{ padding: '16px 0', borderRadius: '100px' }}
                        >
                            {t('logout_CTA')}
                        </Button>

                        <Button
                            design="light"
                            bordered
                            fullWidth
                            disableElevation
                            onClick={onClose}
                            css={{ padding: '16px 0', borderRadius: '100px' }}
                        >
                            {t('cancel_CTA')}
                        </Button>
                    </Stack>
                </Stack>
            </Stack>
        </Dialog>
    );
});
