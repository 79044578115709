import { FC, ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { IconButton, Stack, Typography } from '@mui/material';
import { useTheme } from '@emotion/react';

import { ReactComponent as ArrowIcon } from 'src/assets/svg/control-arrow.svg';
import { getTheme } from 'src/utils/get-theme';

export const BlockWithIcon: FC<{
    icon: ReactNode;
    title: ReactNode;
    body: ReactNode;
    link: string;
    className?: string;
}> = ({ icon, title, body, link, className }) => {
    const { palette, transitions } = useTheme();
    const { isHosTheme } = getTheme();

    return (
        <Stack
            direction="row"
            padding="20px"
            gap="16px"
            alignItems="start"
            component={Link}
            to={link}
            className={className}
            css={{
                textDecoration: 'inherit',
                color: 'inherit',
                ':hover': {
                    '[aria-label="link title"]': {
                        ':after': {
                            width: '100%',
                        },
                    },
                },
            }}
        >
            {icon}

            <Stack flex={1} css={{ gap: '8px' }}>
                <Typography
                    variant="h3"
                    aria-label="link title"
                    width="fit-content"
                    css={{
                        paddingBottom: '2px',
                        position: 'relative',

                        ':after': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            height: '1px',
                            width: 0,
                            bottom: 0,
                            background: palette.text.primary,
                            transition: transitions.create('width'),
                        },
                    }}
                >
                    {title}
                </Typography>

                <Typography
                    variant="body1"
                    color={isHosTheme ? 'text.primary' : 'text.secondary'}
                    aria-label="link text"
                >
                    {body}
                </Typography>
            </Stack>

            <IconButton>
                <ArrowIcon
                    css={{
                        color: palette.common.black,
                        width: '24px',
                        height: '24px',
                        flexShrink: 0,
                        alignSelf: 'center',
                        marginLeft: 'auto',
                        transform: 'rotate(-90deg)',
                    }}
                />
            </IconButton>
        </Stack>
    );
};
