import { Theme } from '@mui/material/styles';

import { hosTheme, theme } from 'src/styles/theme';
import { Themes } from 'src/shared/enum/Themes';

import { Title } from './enum/Title';
import { Favicon } from './enum/Favicon';
import { DesignMap } from './types/DesignMap';

const designsMap: Record<string, DesignMap> = {
    [process.env.REACT_APP_PRIMARY_DOMAIN as string]: {
        title: Title.VITA_CONCIERGE,
        favicon: Favicon.VITA_CONCIERGE,
        theme,
        themeName: Themes.VITA_CONCIERGE,
    },
    [process.env.REACT_APP_HOS_DOMAIN as string]: {
        title: Title.HOUSE_OF_SOCIAL,
        favicon: Favicon.HOUSE_OF_SOCIAL,
        theme: hosTheme,
        themeName: Themes.HOUSE_OF_SOCIAL,
    },
    [process.env.REACT_APP_HOS_SECONDARY_DOMAIN as string]: {
        title: Title.HOUSE_OF_SOCIAL,
        favicon: Favicon.HOUSE_OF_SOCIAL,
        theme: hosTheme,
        themeName: Themes.HOUSE_OF_SOCIAL,
    },
};

const updateTitleAndFavicon = (design: DesignMap) => {
    document.title = design.title as string;

    const faviconElement = document.querySelector('link[rel="icon"]');

    if (faviconElement instanceof HTMLLinkElement) {
        faviconElement.href = design.favicon as string;
    }
};

export const getTheme = (): { themeName: Themes; theme: Theme; isHosTheme: boolean } => {
    const { hostname } = window.location;

    const mainTheme = Object.values(designsMap)[0];

    const design = designsMap[hostname] || mainTheme;

    updateTitleAndFavicon(design);

    return {
        isHosTheme: design.themeName === Themes.HOUSE_OF_SOCIAL,
        themeName: design.themeName,
        theme: design.theme,
    };
};
