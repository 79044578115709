import { FC, ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';
import { useTheme } from '@emotion/react';
import { Alert, Box, Stack, Typography } from '@mui/material';

import { Image } from 'src/components/image';
import { theme } from 'src/styles/theme';
import { isRelativeUrl } from 'src/utils/is-link-relative';
import { ButtonWithIcon } from 'src/components/button-with-icon';

interface Props {
    image: string;
    label?: string;
    title: string;
    body: ReactNode;
    datetime?: string;
    link?: {
        text: string;
        url: string;
        showButton?: boolean;
    };
    onClick?: VoidFunction;
    paper?: boolean;
    className?: string;
}

export const BuildingCard: FC<Props> = ({
    image,
    label,
    title,
    body,
    datetime,
    link,
    onClick,
    paper,
    className,
}) => {
    const { palette } = useTheme();

    const linkProps =
        link &&
        (isRelativeUrl(link.url)
            ? { component: Link, to: link.url }
            : { component: 'a', href: link.url, target: '_blank' });

    return (
        <Stack
            {...linkProps}
            onClick={onClick}
            position="relative"
            width="100%"
            css={{
                background: paper ? palette.background.paper : palette.background.default,
                color: palette.common.black,
                textDecoration: 'none',
            }}
            className={className}
        >
            {link?.showButton && (
                <ButtonWithIcon
                    design="light"
                    bordered
                    css={{
                        width: 'fit-content',
                        position: 'absolute',
                        right: 0,
                        top: 0,
                    }}
                >
                    {link.text}
                </ButtonWithIcon>
            )}

            <Image
                src={image}
                alt={title}
                css={{
                    height: '160px',

                    [theme.breakpoints.up('md')]: {
                        height: '220px',
                    },
                }}
            />

            <Box
                position="relative"
                padding={{ xs: '20px 16px', md: '29px 20px 24px 20px' }}
                className="building-card__description"
            >
                {label && (
                    <Alert
                        variant="filled"
                        icon={false}
                        square
                        css={{
                            width: 'fit-content',
                            padding: '6px 12px',
                            position: 'absolute',
                            top: '-28px',
                            textTransform: 'uppercase',
                            backgroundColor: palette.primary.main,
                        }}
                    >
                        {label}
                    </Alert>
                )}

                <Typography variant="h4" marginBottom="12px" textTransform="uppercase">
                    {title}
                </Typography>

                <Typography variant="body2">{body}</Typography>

                {datetime && (
                    <Typography variant="subtitle2" marginTop="16px">
                        {format(new Date(datetime), "dd.MM.yyyy '●' HH:mm")}
                    </Typography>
                )}
            </Box>
        </Stack>
    );
};
