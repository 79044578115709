import { Fragment, memo } from 'react';
import { matchRoutes, useLocation } from 'react-router-dom';
import { Stack } from '@mui/material';
import { useTheme } from '@emotion/react';

import { routes } from 'src/constants/routes';
import { Header } from 'src/layout/header';
import { Sidebar } from 'src/layout/sidebar/sidebar';
import { getTheme } from 'src/utils/get-theme';

import { InnerRouting } from './inner-routing';
import { Footer } from './footer';

const PAGES_WITHOUT_SIDEBAR = [{ path: routes.community.id }];

const PAGES_WITH_HEADER = [
    { path: routes.myBuilding.index },
    { path: routes.community.index },
    { path: routes.home },
    { path: routes.index },
];

export const Layout = memo(() => {
    const location = useLocation();

    const hideSidebar = matchRoutes(PAGES_WITHOUT_SIDEBAR, location);
    const showHeader = matchRoutes(PAGES_WITH_HEADER, location);
    const { palette } = useTheme();
    const { isHosTheme } = getTheme();

    return (
        <Fragment>
            {showHeader && <Header />}

            <Stack
                component="main"
                direction="row"
                flex={1}
                overflow="auto"
                paddingBottom={{ xs: '48px', md: 0 }}
                css={
                    isHosTheme
                        ? {
                              background: palette.background.paper,
                          }
                        : undefined
                }
            >
                {!hideSidebar && <Sidebar />}

                <Stack flex={1} position="relative" maxWidth="100%" overflow="auto">
                    <InnerRouting />
                </Stack>
            </Stack>

            <Footer />
        </Fragment>
    );
});
