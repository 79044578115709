import { FC } from 'react';
import { Link } from 'react-router-dom';
import { IconButton, Stack, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { routes } from 'src/constants/routes';
import { ReactComponent as ArrowIcon } from 'src/assets/svg/control-arrow.svg';
import { getTheme } from 'src/utils/get-theme';

export const FeedbackAndReviewsCTA: FC<{ className?: string }> = ({ className }) => {
    const { palette, breakpoints } = useTheme();
    const { isHosTheme } = getTheme();

    const { t } = useTranslation();

    return (
        <Stack
            gap={{ xs: '16px', md: '20px' }}
            component={Link}
            to={routes.feedback.index}
            className={className}
            padding={{ md: '40px' }}
            alignItems={{ md: 'center' }}
            textAlign={{ md: 'center' }}
            css={{
                color: palette.text.primary,
                textDecoration: 'none',
                [breakpoints.up('md')]: {
                    background: isHosTheme ? palette.background.default : palette.background.paper,
                },
            }}
        >
            <Typography variant="h3">{t('my_account_feedback_reviews_title')}</Typography>

            <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                gap="16px"
                maxWidth={{ md: '600px' }}
            >
                <Typography
                    variant="body1"
                    color={isHosTheme ? 'text.primary' : 'text.secondary'}
                    aria-label="link text"
                >
                    {t('my_account_feedback_reviews_subtitle')}
                </Typography>

                <IconButton>
                    <ArrowIcon
                        width={24}
                        height={24}
                        color={palette.common.black}
                        css={{
                            flexShrink: 0,
                            alignSelf: 'center',
                            marginLeft: 'auto',
                            transform: 'rotate(-90deg)',
                        }}
                    />
                </IconButton>
            </Stack>
        </Stack>
    );
};
