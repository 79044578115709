import { FC, useMemo, useState } from 'react';
import { useTheme } from '@emotion/react';
import {
    Typography,
    Accordion,
    AccordionDetails,
    AccordionSummary,
    List,
    ListItem,
    Badge,
} from '@mui/material';

import { useDateLocale } from 'src/hooks/use-date-locale';
import { ReactComponent as ArrowIcon } from 'src/assets/svg/control-arrow.svg';
import { getTheme } from 'src/utils/get-theme';

import { formatBookingDate } from '../utils/format-booking-date';

import type { BookingList } from '../api';

const INSTALLMENTS_ARE_PAID = true;

export const BookingSelect: FC<{
    bookingId: string;
    bookings: BookingList;
    onBookingIdChange: (id: string) => void;
}> = ({ bookingId, bookings, onBookingIdChange }) => {
    const { palette, transitions, breakpoints } = useTheme();
    const { isHosTheme } = getTheme();

    const [isExpanded, setExpanded] = useState(false);

    const dateLocale = useDateLocale();

    const selectOptions = useMemo(
        () =>
            bookings.map(({ date_from, date_to, id }) => ({
                id,
                label: `${formatBookingDate(date_from, false, dateLocale)} — ${formatBookingDate(
                    date_to,
                    false,
                    dateLocale
                )}`,
                isPaid: INSTALLMENTS_ARE_PAID,
            })),
        [bookings, dateLocale]
    );

    const selectedItemLabel = selectOptions.find(({ id }) => bookingId === id)?.label;

    const toggleExpanded = () => setExpanded(!isExpanded);

    const handleChange = (id: string) => () => {
        toggleExpanded();
        onBookingIdChange(id);
    };

    return (
        <Accordion
            expanded={isExpanded}
            onChange={toggleExpanded}
            disableGutters
            css={{
                background: isHosTheme ? palette.background.paper : palette.background.default,
                boxShadow: 'none',
                borderBottom: `1px solid ${palette.divider}`,

                '::before': {
                    background: palette.primary.main,
                    width: '100%',
                    margin: 'auto',
                    opacity: '1 !important',
                    top: 'unset',
                    bottom: 0,
                    transition: transitions.create('width'),
                },

                '&.Mui-expanded::before': {
                    width: 0,
                },

                [breakpoints.up('md')]: {
                    display: 'none',
                },
            }}
        >
            <AccordionSummary
                css={{
                    padding: 0,
                    '.MuiAccordionSummary-expandIconWrapper': {
                        position: 'absolute',
                        right: 0,
                    },
                }}
                expandIcon={bookings.length > 1 && <ArrowIcon />}
            >
                <Typography variant="subtitle2" width="100%" textAlign="center">
                    {selectedItemLabel}
                </Typography>
            </AccordionSummary>
            <AccordionDetails css={{ padding: 0 }}>
                <List
                    css={{
                        padding: 0,
                        li: { padding: '12px 0', cursor: 'pointer' },
                    }}
                >
                    {selectOptions.map(
                        (option) =>
                            option.id !== bookingId && (
                                <ListItem key={option.id} onClick={handleChange(option.id)}>
                                    <Badge
                                        variant="dot"
                                        color="primary"
                                        invisible={option.isPaid}
                                        css={{ margin: 'auto', '.BaseBadge-badge': { top: '50%' } }}
                                    >
                                        <Typography
                                            paddingX="16px"
                                            variant="body2"
                                            color="text.secondary"
                                        >
                                            {option.label}
                                        </Typography>
                                    </Badge>
                                </ListItem>
                            )
                    )}
                </List>
            </AccordionDetails>
        </Accordion>
    );
};
