import { FC } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ErrorBoundary } from '@sentry/react';
import { QueryClientProvider } from '@tanstack/react-query';
import { ThemeProvider, CssBaseline, GlobalStyles } from '@mui/material';
import { LocalizationProvider as MuiLocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';

import { queryClient } from 'src/lib/query-client';
import { Auth0HistoryProvider } from 'src/features/auth';
import { globalStyles } from 'src/styles/global-styles';
import { SnackbarProvider } from 'src/features/notifications/providers/snackbar.provider';
import { RootErrorPage } from 'src/pages/root-error.page';
import { getTheme } from 'src/utils/get-theme';

export const AppProvider: FC<{ children: JSX.Element }> = ({ children }) => {
    const { theme } = getTheme();

    return (
        <MuiLocalizationProvider dateAdapter={AdapterDateFns}>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <GlobalStyles styles={globalStyles} />

                <ErrorBoundary fallback={<RootErrorPage />}>
                    <QueryClientProvider client={queryClient}>
                        <BrowserRouter>
                            <QueryParamProvider adapter={ReactRouter6Adapter}>
                                <Auth0HistoryProvider>
                                    <SnackbarProvider>{children}</SnackbarProvider>
                                </Auth0HistoryProvider>
                            </QueryParamProvider>
                        </BrowserRouter>
                    </QueryClientProvider>
                </ErrorBoundary>
            </ThemeProvider>
        </MuiLocalizationProvider>
    );
};
