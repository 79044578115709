import { FC, ReactNode } from 'react';
import { useTheme } from '@emotion/react';
import {
    Accordion,
    AccordionDetails,
    AccordionProps,
    AccordionSummary,
    Box,
    Typography,
} from '@mui/material';

import { ReactComponent as EditIcon } from 'src/assets/svg/pen.svg';
import { getTheme } from 'src/utils/get-theme';

interface Props {
    title: string;
    label?: string;
    error?: string;
    expanded: boolean;
    icon: ReactNode;
    disabled?: boolean;
    onExpand: AccordionProps['onChange'];
    children: ReactNode;
}

export const AccordionField: FC<Props> = ({
    title,
    label,
    error,
    icon,
    disabled,
    expanded,
    onExpand,
    children,
}) => {
    const { palette, shape } = useTheme();
    const { isHosTheme } = getTheme();

    return (
        <Accordion
            disableGutters
            expanded={expanded}
            onChange={onExpand}
            elevation={0}
            disabled={disabled}
            square
            css={{
                background: 'unset !important',
                overflow: 'auto',
                borderTop: `1px solid ${palette.divider}`,

                ':last-of-type': {
                    borderBottom: `1px solid ${palette.divider}`,
                },
            }}
        >
            <AccordionSummary
                css={{
                    padding: '34px 0 28px 0',

                    '.MuiAccordionSummary-content': { display: 'flex', margin: 0 },
                }}
            >
                {icon}

                <Box flex={1} marginLeft="16px">
                    <Typography variant="h3" gutterBottom>
                        {title}
                    </Typography>

                    <Typography variant="body2" color="text.secondary">
                        {label}
                    </Typography>
                </Box>

                {!(expanded || disabled) && (
                    <EditIcon color={isHosTheme ? palette.common.black : palette.grey[600]} />
                )}
            </AccordionSummary>

            <AccordionDetails css={{ padding: '0 0 28px 0', overflow: 'hidden' }}>
                {error && (
                    <div
                        css={{
                            padding: '16px',
                            margin: '16px 0',
                            background: palette.error.light,
                            borderRadius: `${shape.borderRadius}px`,
                        }}
                    >
                        <Typography variant="body2" color="error">
                            {error}
                        </Typography>
                    </div>
                )}

                {children}
            </AccordionDetails>
        </Accordion>
    );
};
