import { FC } from 'react';
import { format } from 'date-fns';
import { useTheme } from '@emotion/react';
import { Stack, Avatar, Typography, useMediaQuery } from '@mui/material';

import { UserData, UserDataFields } from 'src/features/user/api';
import { getTheme } from 'src/utils/get-theme';
import { hosPalette } from 'src/styles/constants';
import { theme } from 'src/styles/theme';

import type { MaintenanceChatMessage } from '../../../api';

const OPERATIONS_TEAM_USER = {
    [UserDataFields.AvatarUrl]: null,
    [UserDataFields.FirstName]: 'Operations',
    [UserDataFields.LastName]: 'Team',
};

export const Message: FC<{
    currentUser: UserData;
    message: MaintenanceChatMessage;
}> = ({ message, currentUser }) => {
    const { palette } = useTheme();
    const { isHosTheme } = getTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('lg'));

    const isFromMyUser = message.inbox;
    const user = isFromMyUser ? currentUser : OPERATIONS_TEAM_USER;
    const username = `${user[UserDataFields.FirstName]} ${user[UserDataFields.LastName]}`;

    let backgroundColor;

    if (isHosTheme) {
        if (isFromMyUser) {
            backgroundColor = hosPalette.primary.main;
        } else {
            backgroundColor = isSmallScreen ? palette.background.paper : palette.background.default;
        }
    } else {
        backgroundColor = isFromMyUser ? palette.secondary.main : palette.background.paper;
    }

    let textColor: string;
    if (isHosTheme) {
        textColor = isFromMyUser ? hosPalette.primary.contrastText : palette.text.primary;
    } else {
        textColor = palette.text.secondary;
    }

    return (
        <Stack direction={isFromMyUser ? 'row-reverse' : 'row'} gap="12px">
            <Avatar
                src={user.avatar_url!}
                alt="user avatar"
                css={{
                    width: '36px',
                    height: '36px',
                    background: isFromMyUser ? palette.secondary.main : undefined,
                    svg: {
                        width: '24px',
                        height: '24px',
                    },
                }}
            />

            <div
                css={{
                    padding: '16px',
                    flex: 1,
                    borderRadius: isFromMyUser ? '4px 0px 4px 4px' : '0px 4px 4px 4px',
                    background: backgroundColor,
                    color: isHosTheme ? textColor : undefined,
                }}
            >
                <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    marginBottom="16px"
                    fontWeight="bold"
                >
                    {!isFromMyUser && <Typography variant="subtitle2">{username}</Typography>}

                    <Typography
                        variant="body2"
                        css={{
                            color: textColor,
                        }}
                    >
                        {format(new Date(message.created_datetime), 'HH:mm')}
                    </Typography>
                </Stack>

                <Typography variant="body2" css={{ wordBreak: 'break-word' }}>
                    {message.message}
                </Typography>
            </div>
        </Stack>
    );
};
