import { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Alert, Paper, Stack, Typography } from '@mui/material';

import { useGetActiveBooking } from 'src/features/bookings';
import { ErrorState } from 'src/components/error-state';
import { Spinner } from 'src/components/spinner';
import { trimText } from 'src/utils/trim-text';
import { GA4 } from 'src/lib/ga4';
import { getPartialTranslation } from 'src/utils/get-partial-translation';
import { hosPalette, palette } from 'src/styles/constants';
import { getTheme } from 'src/utils/get-theme';

import { BuildingNavLink } from '../../building/components/building-nav-link';
import { useGetBuildingNews } from '../hooks/use-get-building-news';
import { ReactComponent as LatestNewsIcon } from '../assets/svg/latest-news.svg';
import { formatBuildingNewsDate } from '../utils/format-building-news-date';
import { BuildingUpdatesStatus } from '../api/constants';

interface Props {
    checkedIn: boolean;
    isFeatureEnabled: boolean;
    className?: string;
}

export const BuildingLatestUpdate: FC<Props> = ({ isFeatureEnabled, checkedIn, className }) => {
    const { data: booking } = useGetActiveBooking();

    const {
        data: news = [],
        isLoading,
        isError,
        isSuccess,
    } = useGetBuildingNews(booking!.id, { enabled: isFeatureEnabled });

    const { t } = useTranslation();

    const [latestNews] = news;
    const { isHosTheme } = getTheme();

    const handleTitleClick = () =>
        GA4.ctaEvent({
            name: 'News Title Click',
            label: t('whats_happening_title'),
            location: 'News Card',
        });

    const handleCTAClick = () =>
        GA4.ctaEvent({
            name: 'News View More Click',
            label: t('whats_happening_see_all_news'),
            location: 'News Card',
        });

    return (
        <BuildingNavLink
            title={t('whats_happening_title')}
            link={{
                text: t('whats_happening_see_all_news'),
                url: 'news',
            }}
            onTitleClick={handleTitleClick}
            onCTAClick={handleCTAClick}
            Icon={LatestNewsIcon}
            checkedIn={checkedIn}
            isFeatureEnabled={isFeatureEnabled}
            className={className}
        >
            {/* Use function because we need pass a node to BuildingNavLink not array. */}
            {(() => {
                if (isError)
                    return <ErrorState title={<Trans i18nKey="whats_happening_error_title" />} />;

                if (isLoading)
                    return (
                        <Typography textAlign="center" padding="37px" color="primary">
                            <Spinner />
                        </Typography>
                    );

                if (isSuccess && latestNews) {
                    const dateFormatted = formatBuildingNewsDate(latestNews.created_date);
                    const isResolved = latestNews.status === BuildingUpdatesStatus.Resolved;

                    const title = getPartialTranslation(latestNews.title);
                    const description = getPartialTranslation(latestNews.description);

                    return (
                        <Paper
                            elevation={0}
                            square
                            css={{
                                position: 'relative',
                                width: '100%',
                                padding: '48px 32px 32px 32px',
                            }}
                        >
                            <Alert
                                icon={false}
                                variant="filled"
                                severity="info"
                                css={{
                                    width: 'fit-content',
                                    padding: '6px 12px',
                                    position: 'absolute',
                                    top: '-4px',
                                    backgroundColor: isHosTheme
                                        ? hosPalette.primary.main
                                        : palette.primary.main,
                                }}
                            >
                                {t('building-news_preview_label')}
                            </Alert>

                            <Stack direction="row" justifyContent="space-between">
                                <Typography
                                    variant="h4"
                                    gutterBottom
                                    color={isResolved ? 'text.disabled' : 'text.primary'}
                                    textTransform="uppercase"
                                    css={{
                                        textDecoration: isResolved ? 'line-through' : undefined,
                                    }}
                                >
                                    {title}
                                </Typography>

                                <Typography variant="body2" color="text.secondary" fontSize="12px">
                                    {t(dateFormatted.key, { count: dateFormatted.diff })}
                                </Typography>
                            </Stack>

                            <Typography variant="body2">{trimText(description, 140)}</Typography>
                        </Paper>
                    );
                }

                return null;
            })()}
        </BuildingNavLink>
    );
};
