import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, Stack, Divider } from '@mui/material';

import { useDateLocale } from 'src/hooks/use-date-locale';

import { Booking } from '../api';
import { formatBookingDate } from '../utils/format-booking-date';

export const BookingInfo: FC<{ booking: Booking }> = ({ booking }) => {
    const { t } = useTranslation();
    const dateLocale = useDateLocale();

    return (
        <div css={{ overflow: 'hidden' }}>
            <Typography variant="h5" marginBottom="16px" fontWeight="bold">
                {t('my_bookings_booking_summary_room_label')} {booking.room}
            </Typography>

            <Divider />

            <Stack spacing="12px" marginTop="24px">
                <Stack direction="row" alignItems="center" justifyContent="space-between">
                    <Typography variant="subtitle2">VITAREF:</Typography>
                    <Typography variant="body2">{booking.vitaref}</Typography>
                </Stack>

                <Stack direction="row" alignItems="center" justifyContent="space-between">
                    <Typography variant="subtitle2">
                        {t('my_bookings_booking_summary_year_label')}:
                    </Typography>
                    <Typography variant="body2">{booking.year}</Typography>
                </Stack>

                <Stack direction="row" alignItems="center" justifyContent="space-between">
                    <Typography variant="subtitle2">
                        {t('my_bookings_booking_summary_start_date_label')}:
                    </Typography>
                    <Typography variant="body2">
                        {formatBookingDate(booking.date_from, true, dateLocale)}
                    </Typography>
                </Stack>

                <Stack direction="row" alignItems="center" justifyContent="space-between">
                    <Typography variant="subtitle2">
                        {t('my_bookings_booking_summary_end_date_label')}
                    </Typography>
                    <Typography variant="body2">
                        {formatBookingDate(booking.date_to, true, dateLocale)}
                    </Typography>
                </Stack>
            </Stack>
        </div>
    );
};
