import { FC, ReactNode } from 'react';
import { useTheme } from '@emotion/react';
import { Badge } from '@mui/material';

import { ReactComponent as PenIcon } from 'src/assets/svg/pen.svg';
import { getTheme } from 'src/utils/get-theme';

export const ProfileAvatarBadge: FC<{
    htmlFor: string;
    children?: ReactNode;
    className?: string;
}> = ({ htmlFor, children, className }) => {
    const { palette } = useTheme();
    const { isHosTheme } = getTheme();

    return (
        <Badge
            htmlFor={htmlFor}
            component="label"
            aria-label="upload avatar"
            overlap="circular"
            color="primary"
            anchorOrigin={{
                horizontal: 'right',
                vertical: 'bottom',
            }}
            badgeContent={<PenIcon color={palette.common.white} width="16px" height="16px" />}
            className={className}
            css={{
                cursor: 'pointer',

                '.MuiBadge-badge': {
                    width: '28px',
                    height: '28px',
                    borderRadius: '50%',
                    ...(isHosTheme && {
                        background: palette.common.black,
                    }),
                },
            }}
        >
            {children}
        </Badge>
    );
};
