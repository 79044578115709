import { FC, ReactNode, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@emotion/react';
import { Stack, Typography, Button, LinearProgress, Box } from '@mui/material';

import { ReactComponent as ArrowIcon } from 'src/assets/svg/control-arrow.svg';
import { historyService } from 'src/services/history.service';
import { routes } from 'src/constants/routes';
import { getTheme } from 'src/utils/get-theme';

interface Props {
    title?: ReactNode;
    actionLabel?: ReactNode;
    showBackIcon?: boolean;
    backIconText?: string;
    progress?: number;
    onBack?: VoidFunction;
    className?: string;
}
export const PrivateHat: FC<Props> = ({
    title,
    actionLabel,
    showBackIcon = true,
    backIconText,
    progress,
    onBack,
    className,
}) => {
    const navigate = useNavigate();
    const { palette, zIndex, custom } = useTheme();
    const { isHosTheme } = getTheme();
    const titleTextRef = useRef<HTMLElement | null>(null);
    const headerRef = useRef<HTMLElement | null>(null);
    const leftActionRef = useRef<HTMLButtonElement | null>(null);

    const [titleTextOffset, setTitleTextOffset] = useState(0);

    const handleBackAction = () => {
        if (historyService.stack > 1) {
            navigate(-1);
        } else {
            navigate(routes.home);
        }

        onBack?.();
    };

    useEffect(() => {
        const resizeObserver = new ResizeObserver(() => {
            const textWidth = titleTextRef.current?.getBoundingClientRect().width || 0;
            const headerWidth = headerRef.current?.getBoundingClientRect().width || 0;
            const leftActionWidth = leftActionRef.current?.getBoundingClientRect().width || 0;

            const leftOffset = headerWidth / 2 - textWidth / 2 - leftActionWidth;
            setTitleTextOffset(leftOffset);
        });

        resizeObserver.observe(headerRef.current!);

        return () => resizeObserver.disconnect();
    }, []);

    return (
        <Box
            className={className}
            css={{
                background: palette.background.default,
                ...(isHosTheme && {
                    background: palette.background.paper,
                }),
            }}
        >
            <Box
                padding={custom.containerPaddings}
                className="PrivateHat__header-wrap"
                css={{
                    position: 'sticky',
                    top: 0,
                    zIndex: zIndex.appBar,
                }}
            >
                <Stack
                    ref={headerRef}
                    component="header"
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    className="PrivateHat__header"
                >
                    {showBackIcon && (
                        <Button
                            ref={leftActionRef}
                            onClick={handleBackAction}
                            color="inherit"
                            css={{
                                padding: 0,

                                ':hover': {
                                    background: 'inherit',
                                },
                            }}
                        >
                            <ArrowIcon
                                width="16px"
                                height="16px"
                                className="PrivateHat__back-icon"
                                css={{
                                    transform: 'rotate(90deg)',
                                }}
                            />

                            {backIconText && (
                                <Box>
                                    <Typography
                                        variant="overlineMedium"
                                        display={{ xs: 'none', md: 'block' }}
                                        marginLeft="1em"
                                    >
                                        {backIconText}
                                    </Typography>
                                </Box>
                            )}
                        </Button>
                    )}

                    <Box flex={1}>
                        <Typography
                            ref={titleTextRef}
                            variant="h3"
                            width="fit-content"
                            textAlign="center"
                            className="PrivateHat__title"
                            style={{
                                marginLeft: `${titleTextOffset}px`,
                            }}
                        >
                            {title}
                        </Typography>
                    </Box>

                    {actionLabel}
                </Stack>
            </Box>

            {progress && (
                <LinearProgress
                    variant="determinate"
                    value={progress}
                    css={{ background: palette.divider, zIndex: zIndex.appBar + 1 }}
                />
            )}
        </Box>
    );
};
